/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('UserStore', () => {
  const ACCAUNTS_URL = 'https://api.rainviewer.com/b2b/accounts';
  const jwtToken = ref(null);
  const loading = ref(false);
  const errors = ref(null);
  const data = ref(null);
  const isConfirmed = ref(false);
  const errorMessage = ref(null);

  jwtToken.value = localStorage.getItem('jwt');

  function reset() {
    jwtToken.value = null;
    loading.value = false;
    errors.value = null;
    data.value = null;
    isConfirmed.value = false;
    errorMessage.value = null;
    localStorage.removeItem('jwt');
  }

  async function fetchData() {
    loading.value = true;
    errors.value = null;
    errorMessage.value = null;
    await fetch(ACCAUNTS_URL, {
      headers: {
        Authorization: `Bearer ${jwtToken.value}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTPS error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.code === 0) {
          data.value = responseData.data;
          isConfirmed.value = !!responseData.data.isConfirmed;
        } else {
          errorMessage.value = responseData.message;
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        errors.value = error.message;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  async function sendData(endpoint, payload) {
    loading.value = true;
    errors.value = null;
    errorMessage.value = null;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    if (jwtToken.value && jwtToken.value !== 'undefined') {
      options.headers.Authorization = `Bearer ${jwtToken.value}`;
    }
    await fetch(ACCAUNTS_URL + endpoint, options)
      .then((response) => {
        if (!response.ok) {
          loading.value = false;
          throw new Error(`HTTPS error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.code === 0) {
          jwtToken.value = responseData.data.token;
          localStorage.setItem('jwt', responseData.data.token);
        } else {
          errorMessage.value = responseData.message;
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        errors.value = error.message;
      })
      .finally(() => {
        loading.value = false;
      });
  }
  return {
    data,
    jwtToken,
    isConfirmed,
    errorMessage,
    fetchData,
    sendData,
    reset,
  };
});
